import {
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
} from "@nextui-org/react";
import { ReactNode } from "react";

type PopoverMenuProps = Pick<
  PopoverProps,
  | "isOpen"
  | "onOpenChange"
  | "placement"
  | "offset"
  | "shouldBlockScroll"
  | "triggerScaleOnOpen"
> & {
  children: ReactNode;
  content: ReactNode;
  className?: string;
};

export function PopoverMenu({
  children,
  content,
  className,
  ...popoverProps
}: PopoverMenuProps) {
  return (
    <Popover radius="sm" {...popoverProps}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent className={className}>{content}</PopoverContent>
    </Popover>
  );
}
