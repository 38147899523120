import { FormHTMLAttributes, ReactNode } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from "react-hook-form";

type FormProps<T extends FieldValues> = Omit<
  FormHTMLAttributes<HTMLFormElement>,
  "onSubmit"
> & {
  children: ReactNode;
  onSubmit: SubmitHandler<T>;
  methods: UseFormReturn<T>;
};

export function Form<T extends FieldValues>({
  children,
  onSubmit,
  methods,
  ...props
}: FormProps<T>) {
  return (
    <FormProvider {...methods}>
      <form
        {...props}
        onSubmit={methods.handleSubmit((data) => onSubmit(data))}
      >
        {children}
      </form>
    </FormProvider>
  );
}
