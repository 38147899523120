export const MAIN_SANDBOX_ID = "main";

export type Sandbox = {
  id: number;
  createdAt: string;
  name: string;
  sandboxId: string;
  revisionSetId: number;
};

export type SandboxResponse = {
  sandbox: Sandbox;
};

export type SandboxesResponse = {
  sandboxes: Sandbox[];
};
