import {
  Textarea as NextUiTextarea,
  TextAreaProps as NextUiTextareaProps,
} from "@nextui-org/react";

export type TextareaProps = NextUiTextareaProps;

export function Textarea({
  classNames,
  variant,
  radius,
  ...restProps
}: TextareaProps) {
  const clNames = classNames ?? {};

  return (
    <NextUiTextarea
      {...restProps}
      variant={variant ?? "faded"}
      radius={radius ?? "sm"}
      classNames={{
        ...clNames,
        // Fixing conflict between tailwindforms and nextui
        // See: https://github.com/nextui-org/nextui/issues/1572
        input: `border-0 focus:ring-0 pl-0 ${clNames.input ?? ""}`,
      }}
    />
  );
}
