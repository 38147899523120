import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { filesize } from "filesize";
import {
  ChangeEvent,
  DragEvent,
  PropsWithChildren,
  useRef,
  useState,
} from "react";
import { tv } from "tailwind-variants";

type FileUploadProps = {
  fileTypes?: Record<string, string>;
  maxSizeBytes?: number;
  className?: string;
  uploadButtonText?: string;
} & (
  | {
      onChange: (files: File[], errors?: string[]) => void;
      multiple: true;
    }
  | {
      onChange: (file: File | undefined, errors?: string[]) => void;
      multiple?: false;
    }
);

const { wrapper, btn } = tv({
  variants: {
    dragging: {
      true: {
        btn: "border-blue-500 bg-blue-50",
      },
      false: {
        btn: "border-gray-300",
      },
    },
  },
  slots: {
    wrapper: "flex items-center",
    btn: "w-full cursor-pointer rounded-md border-2 border-dashed transition-colors duration-200 hover:bg-gray-100",
  },
})();

const preventDefaultAndStopPropagation = (
  event: DragEvent<HTMLButtonElement>
) => {
  event.preventDefault();
  event.stopPropagation();
};

export function FileUpload({
  fileTypes,
  maxSizeBytes,
  onChange,
  className,
  multiple = false,
  uploadButtonText = "Click to upload or drop file here",
  children,
}: PropsWithChildren<FileUploadProps>) {
  const [dragging, setDragging] = useState(false);
  const [invalidFiles, setInvalidFiles] = useState<string[]>([]);
  const [sizeExceededFiles, setSizeExceededFiles] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  if (fileTypes && Object.keys(fileTypes).length === 0) {
    throw new Error(
      "Invalid fileTypes prop. Must provide at least one file type"
    );
  }

  const handleDragToggle = (isDragging: boolean) => setDragging(isDragging);

  const handleDrop = (event: DragEvent<HTMLButtonElement>) => {
    preventDefaultAndStopPropagation(event);
    handleDragToggle(false);
    handleFiles(event.dataTransfer.files);
  };

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleFiles(event.target.files);
  };

  const handleFiles = (files: FileList | null) => {
    if (!files) {
      return;
    }

    const validFiles: File[] = [];
    const newInvalidFiles: string[] = [];
    const newSizeExceededFiles: string[] = [];

    for (const file of files) {
      if (maxSizeBytes !== undefined && file.size > maxSizeBytes) {
        newSizeExceededFiles.push(file.name);
      } else if (validateFile(file, fileTypes)) {
        validFiles.push(file);
      } else {
        newInvalidFiles.push(file.name);
      }
    }

    setInvalidFiles(newInvalidFiles);
    setSizeExceededFiles(newSizeExceededFiles);

    const errors: string[] = [];
    if (newInvalidFiles.length > 0) {
      errors.push(
        `Invalid file${newInvalidFiles.length > 1 ? "s" : ""}: ${newInvalidFiles.join(", ")}`
      );
    }
    if (newSizeExceededFiles.length > 0) {
      errors.push(
        `${newSizeExceededFiles.length} file${newSizeExceededFiles.length > 1 ? "s" : ""} exceeding max size: ${newSizeExceededFiles.join(", ")}`
      );
    }

    if (multiple) {
      (onChange as (files: File[], errors?: string[]) => void)(
        validFiles,
        children ? (errors.length > 0 ? errors : undefined) : undefined
      );
    } else {
      const firstValidFile = validFiles[0];
      (onChange as (file: File | undefined, errors?: string[]) => void)(
        firstValidFile,
        children ? (errors.length > 0 ? errors : undefined) : undefined
      );
    }
  };

  const validateFile = (
    file: File,
    fileTypes?: Record<string, string>
  ): boolean => {
    const fileExtension = file.name.split(".").pop()?.toLowerCase() ?? "";
    return (
      !fileTypes ||
      (fileTypes[fileExtension] !== undefined &&
        fileTypes[fileExtension] === file.type)
    );
  };

  return (
    <div className={wrapper({ className })}>
      <input
        type="file"
        id="file-upload"
        ref={fileInputRef}
        accept={fileTypes ? Object.values(fileTypes).join(",") : undefined}
        multiple={multiple}
        className="hidden"
        onChange={handleFileInputChange}
        aria-label="file-upload"
      />
      <button
        aria-labelledby="file-upload-label"
        className={btn({ dragging })}
        onClick={() => fileInputRef.current?.click()}
        onDragEnter={() => handleDragToggle(true)}
        onDragLeave={() => handleDragToggle(false)}
        onDragOver={preventDefaultAndStopPropagation}
        onDrop={handleDrop}
        tabIndex={0}
      >
        {children ?? (
          <div className="p-6">
            <CloudArrowUpIcon className="mx-auto mb-2 h-8 w-8 text-gray-500" />
            <p id="file-upload-label">{uploadButtonText}</p>
            {(invalidFiles.length > 0 || sizeExceededFiles.length > 0) && (
              <div className="text-sm text-red-500">
                {invalidFiles.length > 0 && (
                  <p>{`Invalid file${invalidFiles.length > 1 ? "s" : ""}: ${invalidFiles.join(", ")}`}</p>
                )}
                {sizeExceededFiles.length > 0 && (
                  <p>{`${sizeExceededFiles.length} file${sizeExceededFiles.length > 1 ? "s" : ""} exceeding max size: ${sizeExceededFiles.join(", ")}`}</p>
                )}
              </div>
            )}
            <p className="text-sm uppercase text-gray-500">
              {fileTypes && Object.keys(fileTypes).join(", ")}{" "}
              {maxSizeBytes && `(Max ${filesize(maxSizeBytes, { round: 0 })})`}
            </p>
          </div>
        )}
      </button>
    </div>
  );
}
