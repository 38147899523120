export enum UserPermissions {
  VIEW_PRICING_PAGE = "view_pricing_page",
  VIEW_NEW_REPORT_PAGE = "view_new_report_page",
  VIEW_PAST_REPORTS = "view_past_reports",
  VIEW_VIN_DECODER_PAGE = "view_vin_decoder_page",
  VIEW_MY_SHOPS = "view_my_shops",
  VIEW_ADAS_INSIGHTPULSE_PAGE = "view_adas_insightpulse_page",
  VIEW_CRM_PAGE = "view_crm_page",
  VIEW_SERVICES_PAGE = "view_services_page",
  VIEW_WORK_ORDERS_PAGE = "view_work_orders_page",
  MANAGE_WORK_ORDER_STATUSES = "manage_work_order_statuses",
  CREATE_WORK_ORDERS = "create_work_orders",
  VIEW_TECHNICIANS_PAGE = "view_technicians_page",
  VIEW_INVOICES_PAGE = "view_invoices_page",
  CONNECT_TO_QUICKBOOKS = "connect_to_quickbooks",
  CONNECT_TO_STRIPE = "connect_to_stripe",
  VIEW_STRIPE_CUSTOMER_PORTAL = "view_stripe_customer_portal",
  SEE_CUSTOM_BRANDING = "see_custom_branding",
  SHOW_SHOP_SELECT_DROPDOWN = "show_shop_select_dropdown",
  VIEW_DOCUMENTATION_PAGE = "view_documentation_page",
  ASSIGN_CCC_TO_ALL_SHOPS = "assign_ccc_to_all_shops",
  ASSIGN_CCC_TO_PERMITTED_SHOPS = "assign_ccc_to_permitted_shops",
  PERMIT_CCC_SHOPS = "permit_ccc_shops",
  VIEW_SUB_ACCOUNTS = "view_sub_accounts",
  CREATE_SUB_ACCOUNTS = "create_sub_accounts",
  VIEW_ALL_SHOPS = "view_all_shops",
  ENABLE_RETAIL_PRICE_ON_REPORT = "enable_retail_price_on_report",
  ENABLE_WHITE_LABELING = "enable_white_labeling",
  EXPORT_REPORTS_TO_CSV = "export_reports_to_csv",
  ARCHIVE_REPORT = "archive_report",
  VIEW_READ_RECEIPTS = "view_read_receipts",
  UPDATE_OPERATION_PRICES = "update_operation_prices",
  ASSIGN_TECHNICIAN_TO_WORK_ORDER = "assign_technician_to_work_order",
  BE_ASSIGNED_WORK_ORDERS = "be_assigned_work_orders",
  BE_ASSIGNED_INVOICES = "be_assigned_invoices",
  UPDATE_BUSINESS_DETAILS = "update_business_details",
  UPDATE_CUSTOM_BRANDING = "update_custom_branding",
  DELETE_WORK_ORDERS = "delete_work_orders",
  MANAGE_USER_ROLES = "manage_user_roles",
  VIEW_USER_MANAGEMENT = "view_user_management",
  CREATE_INVOICE_FROM_ESTIMATE = "create_invoice_from_estimate",
  CREATE_INVOICE_FROM_WORK_ORDER = "create_invoice_from_work_order",
  CREATE_BLANK_INVOICE = "create_blank_invoice",
  EDIT_EXISTING_INVOICE = "edit_existing_invoice",
  CREATE_SHOP = "create_shop",
  VIEW_WORK_ORDERS_FROM_ALL_ASSIGNED_SHOPS = "view_work_orders_from_all_assigned_shops",
  MANAGE_API_KEYS = "manage_api_keys",
}

export type Permission = {
  id: number;
  permission: UserPermissions;
  description: string;
};
