import { ReactNode } from "react";

import { Button } from "../Button/Button";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../Modal/Modal";

export type ConfirmProps = {
  title?: ReactNode;
  text: ReactNode;
  cancelLabel?: ReactNode;
  confirmLabel?: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
};

export function Confirm({
  title = "Confirm",
  cancelLabel = "Cancel",
  confirmLabel = "Confirm",
  text,
  onConfirm,
  onCancel,
}: ConfirmProps) {
  return (
    <Modal
      isOpen={true}
      onClose={() => onCancel()}
      scrollBehavior="inside"
      placement="center"
      classNames={{
        base: "rounded-md",
        header: "text-gray-800 border-b border-gray-200 px-4 py-3 text-md",
        body: "px-4",
        footer: "px-4 flex w-full justify-end gap-2",
        closeButton: "top-2",
      }}
    >
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <div className="mt-2 text-base text-gray-500">{text}</div>
        </ModalBody>
        <ModalFooter>
          <Button radius="sm" variant="bordered" onClick={() => onCancel()}>
            {cancelLabel}
          </Button>
          <Button radius="sm" color="danger" onClick={() => onConfirm()}>
            {confirmLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
