import { ColumnStateParams } from "ag-grid-community";

export enum TableColumnStatesColumnType {
  ALL_REPORTS = "all_reports",
  FILE_UPLOAD = "file_upload",
  CCC_SECURE = "ccc_secure_share",
  EMS_INTEGRATION = "ems_integration",
  PDF_AUTO_UPLOAD = "pdf_auto_upload",
  VIN_DECODE = "vin_decode",
  MECHANICAL_VIN_DECODE = "mechanical_vin_decode",
  API_INTEGRATION = "api_integration",
}

export type UpdateTableColumnStatesPayload = {
  tableName: TableColumnStatesColumnType;
  newState: ColumnStateParams[];
};

export type TableColumnState = {
  id: number;
  table_name: TableColumnStatesColumnType;
  customer_id: number;
  state: ColumnStateParams[];
};

export type TableColumnStateResponse = {
  tableColumnStates: TableColumnState[];
};

export type TableColumnStateData = Record<string, ColumnStateParams[]>;
