import { ICellRendererParams } from "ag-grid-community";
import { ReactNode } from "react";

import { convertToDollarsString } from "@revv/utils";

import { Link } from "../Link/Link";
import { Tooltip } from "../Tooltip/Tooltip";

type GridCellProps<T> = {
  data?: ICellRendererParams<T>["data"];
  value?: ICellRendererParams<T>["value"];
  valueFormatted?: ICellRendererParams<T>["valueFormatted"];
  type?: "text" | "link" | "date" | "tooltip" | "money";
};

export function GridLinkCell<T>(props: Omit<GridCellProps<T>, "type">) {
  return <GridCell {...props} type="link" />;
}

export function GridDateCell<T>(props: Omit<GridCellProps<T>, "type">) {
  return <GridCell {...props} type="date" />;
}

export function GridTooltipCell<T>(props: Omit<GridCellProps<T>, "type">) {
  return <GridCell {...props} type="tooltip" />;
}

export function GridMoneyCell<T>(props: Omit<GridCellProps<T>, "type">) {
  return <GridCell {...props} type="money" />;
}

export function GridCell<T>({
  data,
  value,
  valueFormatted,
  type = "text",
}: GridCellProps<T>) {
  let content: ReactNode;

  const displayValue = valueFormatted || value;

  switch (type) {
    case "link": {
      content = (
        <Link isExternal href={value} className="block truncate">
          {value}
        </Link>
      );
      break;
    }

    case "date": {
      const date = value ? new Date(value) : undefined;
      const cellValue = date ? date.toLocaleDateString() : "";
      const tooltipValue = date ? (
        <div className="p-3">
          {cellValue} {date.toLocaleTimeString()}{" "}
        </div>
      ) : (
        ""
      );

      content = (
        <Tooltip content={tooltipValue} delay={300}>
          <div className="truncate">{cellValue}</div>
        </Tooltip>
      );
      break;
    }

    case "tooltip": {
      const tooltipValue = <div className="p-3">{value}</div>;

      content = (
        <Tooltip content={tooltipValue} delay={300}>
          <div className="truncate">{value}</div>
        </Tooltip>
      );
      break;
    }

    case "money": {
      content = (
        <div className="truncate">${convertToDollarsString(value)}</div>
      );
      break;
    }

    default: {
      content = <div className="truncate">{displayValue}</div>;
    }
  }

  if (!data) {
    content = (
      <div
        data-testid="loading"
        className="h-4 w-full animate-pulse rounded-md bg-gray-200"
      />
    );
  }

  return (
    <div data-testid="cell-data" className="flex h-full items-center">
      {content}
    </div>
  );
}
