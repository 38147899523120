import { get } from "lodash-es";
import { ComponentProps } from "react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

import { Textarea } from "../Textarea/Textarea";
import { FormErrorMessage } from "./FormErrorMessage";

export type FormTextareaProps = ComponentProps<typeof Textarea> & {
  name: string;
  rules?: UseControllerProps["rules"];
};

export function FormTextarea({
  name,
  rules,
  classNames,
  ...props
}: FormTextareaProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => {
        const isInvalid = !!get(formState.errors, name);
        const inputWrapper = `${isInvalid ? "border-red-500" : ""} ${classNames?.innerWrapper ?? ""}`;

        return (
          <>
            <Textarea
              {...props}
              classNames={{
                ...classNames,
                inputWrapper,
              }}
              aria-label={name}
              aria-invalid={isInvalid}
              aria-errormessage={`${name}-error`}
              name={name}
              value={field.value || ""}
              onChange={field.onChange}
              onClear={() => field.onChange("")}
            />
            <FormErrorMessage name={name} />
          </>
        );
      }}
    />
  );
}
