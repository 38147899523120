import { get } from "lodash";
import { useFormContext } from "react-hook-form";

type FormErrorMessageProps = {
  name: string;
};

export function FormErrorMessage({ name }: FormErrorMessageProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);

  if (typeof error?.message === "string" && !!error?.message) {
    return (
      <div
        role="alert"
        id={`${name}-error`}
        className="p-1 text-sm text-red-500"
      >
        {error.message}
      </div>
    );
  }
}
