type FilterServiceByValueOptions = {
  shouldPassOnUndefined?: boolean;
};

const filterServiceByValueDefaultOptions: FilterServiceByValueOptions = {
  shouldPassOnUndefined: true,
};

export function filterServiceByValue<TValue = unknown>(
  value: TValue,
  selectedFilterValue: TValue,
  { shouldPassOnUndefined } = filterServiceByValueDefaultOptions
) {
  if (shouldPassOnUndefined && selectedFilterValue === undefined) {
    return true;
  }

  return value === selectedFilterValue;
}
