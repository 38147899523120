export enum ServiceType {
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
}

export enum ServiceOperationType {
  ADAS = "ADAS Operations",
  STEERING = "Steering Operations",
  SAFETY = "Safety Operations",
  FUNCTIONAL = "Functional Operations",
}

export enum ServiceCalibrationType {
  STATIC = "STATIC",
  DYNAMIC = "DYNAMIC",
  DUAL = "DUAL",
  OTHER = "OTHER",
}

export type Service = {
  id: number;
  accountId: number;
  shopId: number;
  name: string;
  serviceType: ServiceType;
  operationType: ServiceOperationType;
  calibrationType: ServiceCalibrationType;
  autopopulate: boolean;
  createdBy: number;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
  makes: string[];
  description?: string;
  price?: number;
  defaultLinkUrl?: string;
  defaultLinkText?: string;
  legacyId?: number;
};

export type ServicesResponse = {
  defaultServices: Service[];
  customServices: Service[];
};

export type UpdateServiceResponse = {
  service: Service;
};

export type ServicesQueryData = {
  [operationType in ServiceOperationType]: {
    [serviceType in ServiceType]: Service[];
  };
};
