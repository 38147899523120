import { cn, SlotsToClasses } from "@nextui-org/react";

export function mergeClassNames<T extends string>(
  baseClassNames: SlotsToClasses<T>,
  extendingClassNames: SlotsToClasses<T>
) {
  const result: SlotsToClasses<T> = {};

  for (const classes of [baseClassNames, extendingClassNames]) {
    for (const [slotName, className] of Object.entries(classes)) {
      const slotKey = slotName as T;
      if (typeof className === "string") {
        result[slotKey] = cn(result[slotKey], className);
      }
    }
  }

  return result;
}
