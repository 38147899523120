import { IDatasource } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject, useMemo } from "react";

import { Page } from "@revv/state";

export function useInfiniteSource<T>(
  getPage: (pageNumber: number) => Promise<Page<T>>,
  pageSize: number,
  gridRef?: RefObject<AgGridReact>
): IDatasource {
  return useMemo<IDatasource>(() => {
    return {
      rowCount: undefined,
      getRows: async (params) => {
        const pageNumber = Math.floor(params.startRow / pageSize);

        try {
          const page = await getPage(pageNumber);

          params.successCallback(
            page.items,
            page.items.length < pageSize ? page.count : undefined
          );
        } catch {
          params.failCallback();
        } finally {
          const api = gridRef?.current?.api;

          if (api) {
            const count = api.getInfiniteRowCount();

            if (count === 0) {
              api.showNoRowsOverlay();
            } else {
              api.hideOverlay();
            }
          }
        }
      },
    };
  }, [getPage, pageSize, gridRef]);
}
