import { createContext, ReactNode, useState } from "react";

import { Confirm, ConfirmProps } from "./Confirm";

type ConfirmContextValue = {
  showConfirm: (options: ConfirmProps) => void;
};

export const ConfirmContext = createContext<ConfirmContextValue | undefined>(
  undefined
);

type ConfirmProviderProps = {
  children: ReactNode;
};

export function ConfirmProvider({ children }: ConfirmProviderProps) {
  const [options, setOptions] = useState<ConfirmProps>();

  const handleCancel = () => {
    options?.onCancel();
    setOptions(undefined);
  };

  const handleConfirm = () => {
    options?.onConfirm();
    setOptions(undefined);
  };

  return (
    <ConfirmContext.Provider value={{ showConfirm: setOptions }}>
      {children}
      {options && (
        <Confirm
          {...options}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
    </ConfirmContext.Provider>
  );
}
