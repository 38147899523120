import {
  Link as NextUiLink,
  LinkProps as NextUiLinkProps,
} from "@nextui-org/react";
import { forwardRef } from "react";

export type LinkProps = NextUiLinkProps & {
  defaultSize?: "sm" | "md" | "lg";
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ defaultSize = "sm", size, ...props }, ref) => (
    <NextUiLink {...props} size={size || defaultSize} ref={ref} />
  )
);
