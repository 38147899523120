import { OverlayPlacement } from "@nextui-org/aria-utils";
import { Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { ReactNode, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";

type HoverProps = {
  children: ReactNode;
  content: ReactNode;
  className?: string;
  placement?: OverlayPlacement;
  triggerScaleOnOpen?: boolean;
};

export function Hover({
  children,
  content,
  className,
  placement,
  triggerScaleOnOpen = false,
}: HoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const setIsOpenDebounced = useDebounceCallback(setIsOpen, 300);

  return (
    <Popover
      placement={placement}
      isOpen={isOpen}
      triggerScaleOnOpen={triggerScaleOnOpen}
      radius="sm"
    >
      <PopoverTrigger
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpenDebounced(false)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {children}
      </PopoverTrigger>
      <PopoverContent
        className={className}
        onMouseEnter={() => setIsOpenDebounced.cancel()}
        onMouseLeave={() => setIsOpen(false)}
        onClick={() => setIsOpen(false)}
      >
        {content}
      </PopoverContent>
    </Popover>
  );
}
