import { ParsedQueryParams } from "../utils/queryParams";
import { KeywordGroupPayload } from "./keywordGroups";

export enum RevisionTarget {
  SENSORS = "sensors",
  RULE_SETS = "rule-sets",
  KEYWORDS_GROUPS = "keyword-groups",
}

export enum RevisionOperation {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

export enum RevisionSetStatus {
  DRAFT = "draft",
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  RESTORED = "restored",
}

type UpdateSensorEntry = {
  field:
    | "procedureType"
    | "procedureLink"
    | "requirementLink"
    | "secondaryProcedureLink"
    | "secondaryRequirementLink";
  value: string;
};

export type UpdateSensorsPayload = {
  query: ParsedQueryParams;
  entries: UpdateSensorEntry[];
};

type CreateKeywordGroupPayload = {
  keywordGroup: KeywordGroupPayload;
};

type UpdateKeywordGroupPayload = {
  id: number;
  keywordGroup: KeywordGroupPayload;
};

type DeleteKeywordGroupPayload = {
  id: number;
};

export type RevisionSetRevision = {
  id: number;
  createdAt: string;
  revisionId: number;
  revisionSetId: number;
  revisionCreatedAt: string;
  rank: number;
  description: string;
  createdBy: string;
  reviewedAt?: string;
  reviewedBy?: string;
} & (
  | {
      target: RevisionTarget.SENSORS;
      operation: RevisionOperation.UPDATE;
      data: UpdateSensorsPayload;
    }
  | {
      target: RevisionTarget.KEYWORDS_GROUPS;
      operation: RevisionOperation.CREATE;
      data: CreateKeywordGroupPayload;
    }
  | {
      target: RevisionTarget.KEYWORDS_GROUPS;
      operation: RevisionOperation.UPDATE;
      data: UpdateKeywordGroupPayload;
    }
  | {
      target: RevisionTarget.KEYWORDS_GROUPS;
      operation: RevisionOperation.DELETE;
      data: DeleteKeywordGroupPayload;
    }
);

export type RevisionSet = {
  id: number;
  status: RevisionSetStatus;
  description: string;
  createdAt: string;
  createdBy: string;
  reviewedAt?: string;
  reviewedBy?: string;
  revisions: RevisionSetRevision[];
};

export type RevisionSetsResponse = {
  revisionSets: RevisionSet[];
  count: number;
};

export type RevisionSetResponse = {
  revisionSet: RevisionSet;
};
