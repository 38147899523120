import { SortChangedEvent } from "ag-grid-community";

export function defaultSortHandler<T>({
  columns,
}: Pick<SortChangedEvent<T>, "columns">) {
  if (!columns) {
    return;
  }

  const lastCol = columns.at(-1);
  const colId = lastCol?.getColId();
  const direction = lastCol?.getSort();
  const sortParams =
    colId && direction
      ? { sortBy: colId, sortOrder: direction }
      : { sortBy: "", sortOrder: "" as const };

  return sortParams;
}
