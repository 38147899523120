import {
  Button as NextUiButton,
  ButtonProps as NextUiButtonProps,
} from "@nextui-org/react";
import { forwardRef } from "react";

export type ButtonProps = NextUiButtonProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => <NextUiButton {...props} ref={ref} />
);
