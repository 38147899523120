import { get } from "lodash-es";
import { ComponentProps, FocusEventHandler } from "react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { tv } from "tailwind-variants";

import { convertToTwoPlaceDecimalString } from "@revv/utils";

import { MoneyInput } from "../MoneyInput/MoneyInput";
import { FormErrorMessage } from "./FormErrorMessage";

const slots = tv({
  slots: {
    inputWrapper: "",
  },
  variants: {
    isInvalid: {
      true: {
        inputWrapper: "border-red-500",
      },
    },
  },
});

export type FormMoneyInputProps = ComponentProps<typeof MoneyInput> & {
  name: string;
  rules?: UseControllerProps["rules"];
  formatValueOnBlur?: boolean;
};

export function FormMoneyInput({
  name,
  rules,
  classNames,
  formatValueOnBlur = false,
  ...props
}: FormMoneyInputProps) {
  const { control } = useFormContext();

  const { inputWrapper } = slots();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => {
        const isInvalid = !!get(formState.errors, name);

        const handleFormatValueOnBlur: FocusEventHandler = () => {
          field.onChange(convertToTwoPlaceDecimalString(field.value));
          field.onBlur();
        };

        return (
          <>
            <MoneyInput
              {...props}
              aria-label={name}
              aria-invalid={isInvalid}
              aria-errormessage={`${name}-error`}
              name={name}
              value={field.value || ""}
              onBlur={
                formatValueOnBlur ? handleFormatValueOnBlur : field.onBlur
              }
              onChange={field.onChange}
              onClear={props.isClearable ? () => field.onChange("") : undefined}
              classNames={{
                ...classNames,
                inputWrapper: inputWrapper({
                  isInvalid,
                  className: classNames?.inputWrapper,
                }),
              }}
            />
            <FormErrorMessage name={name} />
          </>
        );
      }}
    />
  );
}
