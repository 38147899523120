export type CCCReportHistoryRow = {
  id: number;
  shopName: string;
  cccWebhookExtractedId: number;
  shopId: number;
  estimateId: number;
  processedAt: string;
  processingCode: number;
  cccTrigger: string;
  documentId: string;
  documentCreatedAt: string;
  repairOrderId: string;
  cccId: string;
  vin: string;
};

export enum CCCReportDisplayTypes {
  REPORTS = "reports",
  ALL_OTHER_REPORTS = "all_other_reports",
}

export enum CCCProcessingCodes {
  SUCCESS = "0",
  INACTIVE_CUSTOMER = "1",
  SKIPPED = "2",
  INVALID_STRIPE_SETUP = "3",
  NO_DOC_CREATED_AT = "4",
  NO_TRIGGER = "5",
  NO_CCC_ID_LAST_UPDATED = "6",
  RO_REQUIRED = "7",
  YEAR_FILTERED = "8",
  MAKE_FILTERED = "9",
  ESTIMATE_TOO_OLD = "10",
  UNKNOWN_ERROR = "999",
}

export type CCCHistoryResponse = {
  items: CCCReportHistoryRow[];
  count: number;
};

export type FetchCCCHistoryResponse = {
  data: CCCReportHistoryRow[];
  totalCount: number;
};
