export enum KeywordGroupTypes {
  CAR_MAKE = "car-make",
  CAR_MODEL = "car-model",
  CAR_YEAR = "car-year",
  FEATURE = "feature",
  SENSOR_LOCATION = "sensor-location",
  SENSOR_CATEGORY = "sensor-category",
  SENSOR_NAME = "sensor-name",
  SENSOR_ADASSYSTEM = "sensor-adassystem",
  SENSOR_TOOL = "sensor-tool",
  ESTIMATE_LINE = "estimate-line",
  ESTIMATE_COMPANY = "estimate-company",
  ESTIMATE_CATEGORY = "estimate-category",
  HEADING = "heading",
  BOOLEAN = "boolean",
}

export type KeywordGroupRow = {
  id: string;
  name: string;
  type: KeywordGroupTypes;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type KeywordGroup = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  valueIds?: number[];
  values?: (string | number | boolean)[];
  words?: string[];
} & (
  | {
      type:
        | KeywordGroupTypes.CAR_MAKE
        | KeywordGroupTypes.SENSOR_LOCATION
        | KeywordGroupTypes.SENSOR_CATEGORY
        | KeywordGroupTypes.SENSOR_NAME
        | KeywordGroupTypes.SENSOR_ADASSYSTEM
        | KeywordGroupTypes.SENSOR_TOOL;
      valueIds: number[];
    }
  | {
      type:
        | KeywordGroupTypes.CAR_MODEL
        | KeywordGroupTypes.FEATURE
        | KeywordGroupTypes.ESTIMATE_LINE
        | KeywordGroupTypes.ESTIMATE_COMPANY
        | KeywordGroupTypes.ESTIMATE_CATEGORY
        | KeywordGroupTypes.HEADING;
      values: string[];
      words?: string[];
    }
  | {
      type: KeywordGroupTypes.CAR_YEAR;
      values: number[];
    }
  | {
      type: KeywordGroupTypes.BOOLEAN;
      values: boolean[];
    }
);

export type ListKeywordGroupsResponse = {
  keywordGroups: KeywordGroupRow[];
  count: number;
};

export type GetKeywordGroupResponse = {
  keywordGroup: KeywordGroup;
};

export type UpdateKeywordGroupResponse = {
  message: string;
};

export type CreateKeywordGroupResponse = {
  message: string;
  id: number;
};

export type DeleteKeywordGroupResponse = {
  message: string;
};

export type KeywordGroupPayload = Omit<
  KeywordGroup,
  "id" | "createdAt" | "updatedAt"
>;

export type UpdateKeywordGroupRequest = {
  keywordGroup: KeywordGroupPayload;
  description: string;
};

export type CreateKeywordGroupRequest = {
  keywordGroup: KeywordGroupPayload;
  description: string;
};

export type DeleteKeywordGroupRequest = {
  description: string;
};
