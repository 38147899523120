import {
  Tooltip as NextUiTooltip,
  TooltipPlacement as NextUiTooltipPlacement,
  TooltipProps as NextUiTooltipProps,
} from "@nextui-org/react";

export type TooltipPlacement = NextUiTooltipPlacement;

export type TooltipProps = NextUiTooltipProps;

export function Tooltip(props: TooltipProps) {
  return <NextUiTooltip {...props} />;
}
