import { get } from "lodash-es";
import { ComponentProps } from "react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { tv } from "tailwind-variants";

import { Input } from "../Input/Input";
import { FormErrorMessage } from "./FormErrorMessage";

const slots = tv({
  slots: {
    inputWrapper: "",
  },
  variants: {
    isInvalid: {
      true: {
        inputWrapper: "border-red-500",
      },
    },
  },
});

export type FormInputProps = ComponentProps<typeof Input> & {
  name: string;
  rules?: UseControllerProps["rules"];
};

export function FormInput({
  name,
  rules,
  classNames,
  ...props
}: FormInputProps) {
  const { control } = useFormContext();

  const { inputWrapper } = slots();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => {
        const isInvalid = !!get(formState.errors, name);

        return (
          <>
            <Input
              {...props}
              classNames={{
                ...classNames,
                inputWrapper: inputWrapper({
                  isInvalid,
                  className: classNames?.inputWrapper,
                }),
              }}
              aria-label={name}
              aria-invalid={isInvalid}
              aria-errormessage={`${name}-error`}
              name={name}
              value={field.value || ""}
              onChange={field.onChange}
              onClear={props.isClearable ? () => field.onChange("") : undefined}
            />
            <FormErrorMessage name={name} />
          </>
        );
      }}
    />
  );
}
