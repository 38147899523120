import { SensorRow } from "../report";

export type GetRuleSetWithValuesResponse = {
  ruleSet: RuleSet;
};

export type GetRuleSetWithRefsResponse = {
  ruleSet: RuleSetWithRefs;
};

export type ListRuleSetsResponse = {
  ruleSets: RuleSetRow[];
  count: number;
};

export type RuleValues = (boolean | number | string)[];

export enum RuleOperators {
  IS_EMPTY = "is-empty",
  EQUALS = "equals",
  DOES_NOT_EQUAL = "does-not-equal",
  GREATER_THAN_OR_EQUAL = "greater-than-or-equal",
  CONTAINS = "contains",
  DOES_NOT_CONTAIN = "does-not-contain",
  CONTAINS_OR_EMPTY = "contains-or-empty",
  DOES_NOT_CONTAIN_OR_EMPTY = "does-not-contain-or-empty",
}

export type Rule<T = string> = {
  field: T;
  operator: RuleOperators;
  values: RuleValues;
};

export type CarRule = {
  field: "make" | "model" | "year" | "features";
  operator: RuleOperators;
  values: RuleValues;
};

export type CarRuleContext = {
  make: string;
  model: string;
  year: number;
  /**
   * An array of featureName's of ChromeData features related to this car
   */
  features: string[];
};

export type EstimateRule = {
  field:
    | "company"
    | "allLines"
    | "allLinesNoWithText"
    | "allLinesNoWithoutText"
    | "allLinesNoWithNoWithoutText"
    | "allLinesWithText"
    | "allLinesWithoutText"
    | "category"
    | "line"
    | "lineNoWithText"
    | "lineNoWithoutText"
    | "lineNoWithNoWithoutText"
    | "lineWithText"
    | "lineWithoutText";
  operator: RuleOperators;
  values: RuleValues;
};

export type EstimateRuleContext = {
  company: string;
  category: string;
  allLines: string[];
  allLinesNoWithText: string[];
  allLinesNoWithoutText: string[];
  allLinesNoWithNoWithoutText: string[];
  allLinesWithText: string[];
  allLinesWithoutText: string[];
  line: string;
  lineNoWithText: string;
  lineNoWithoutText: string;
  lineNoWithNoWithoutText: string;
  lineWithText: string;
  lineWithoutText: string;
};

export type SensorRule = {
  field:
    | "locations"
    | "category"
    | "name"
    | "adasSystems"
    | "tools"
    | "features"
    | "heading"
    | "isAlignmentRequiredInReport"
    | "isSensorTriggered"
    | "isProgrammingTriggered";
  operator: RuleOperators;
  values: RuleValues;
};

export type SensorRuleContext = {
  locations: string[];
  category: string;
  name: string;
  adasSystems: string[];
  tools: string[];
  features: string[];
  heading: string;
  isAlignmentRequiredInReport: boolean;
  isSensorTriggered: boolean;
  isProgrammingTriggered: boolean;
};

export type FeatureRule = {
  field: "featureNameNoBrand" | "featureName";
  operator: RuleOperators;
  values: RuleValues;
};

export type FeatureRuleContext = {
  [k in FeatureRule["field"]]: string;
};

export type RuleSetMetadata = {
  part?: string;
  name?: string;
  note?: string;
  procedure?: string;
  procedureType?: string;
  procedureLink?: string;
  requirementLink?: string;
  isSensorTriggered?: boolean;
  isCollisionTriggered?: boolean;
  isMergeDisabled?: boolean;
  isWheelAlignmentRequired?: boolean;
  heading?: string;
  operation?: string;
  hardcodedSensor?: Partial<SensorRow>;
  locations?: string[];
  isCalibrationRequired?: boolean;
  allowedParts?: string[];
};

export type RuleSet = {
  id: string;

  type:
    | "trigger-sensor"
    | "trigger-hardcoded-sensor"
    | "trigger-functional"
    | "trigger-hardcoded-safety"
    | "override-metadata"
    | "add-features"
    | "add-optional-features"
    | "remove-sensor"
    | "filter-sensor-cause";
  metadata: RuleSetMetadata;

  estimateRules?: EstimateRule[];
  carRules?: CarRule[];
  sensorRules?: SensorRule[];
  featureRules?: FeatureRule[];
};

export type RuleSetRow = {
  id: string;
  type: RuleSet["type"];
  description?: string;
  createdAt: Date;
  updatedAt: Date;
};

export enum RuleTypes {
  CAR = "car",
  ESTIMATE = "estimate",
  SENSOR = "sensor",
  FEATURE = "feature",
}

export type RuleWithRefs = {
  type: RuleTypes;
  field:
    | CarRule["field"]
    | EstimateRule["field"]
    | SensorRule["field"]
    | FeatureRule["field"];
  operator: RuleOperators;
  keywordGroups: string[];
};

export type RuleSetWithRefs = {
  id: string;
  type: RuleSet["type"];
  metadata: Omit<RuleSetMetadata, "hardcodedSensor"> & {
    hardcodedSensorId?: number;
  };
  description?: string;
  rules: RuleWithRefs[];
};

export type RuleSetPayload = Omit<RuleSetWithRefs, "id">;

export type UpdateRuleSetRequest = {
  ruleSet: RuleSetPayload;
  description: string;
};

export type UpdateRuleSetResponse = {
  message: string;
};

export type CreateRuleSetRequest = {
  ruleSet: RuleSetPayload;
  description: string;
};

export type CreateRuleSetResponse = {
  message: string;
  id: string;
};

export type DeleteRuleSetRequest = {
  description: string;
};

export type DeleteRuleSetResponse = {
  message: string;
};
