import { SlotsToClasses } from "@nextui-org/react";
import { ReactNode, WheelEventHandler } from "react";
import { tv } from "tailwind-variants";

import { Input, InputProps } from "../Input/Input";

export type MoneyInputProps = Omit<InputProps, "type"> & {
  classNames?: InputProps["classNames"] & SlotsToClasses<"startContentWrapper">;
  labelPlacement?: Extract<
    InputProps["labelPlacement"],
    "outside" | "outside-left"
  >;
  startContent?: ReactNode;
  changeValueOnScroll?: boolean;
};

const customSlots = tv({
  slots: {
    startContentWrapper: "shrink-0 pr-1 text-sm font-medium text-gray-500",
  },
});

const handleValueChangeOnScroll: WheelEventHandler<HTMLInputElement> = (e) => {
  e.currentTarget.blur();
};

export function MoneyInput({
  startContent = "$",
  labelPlacement = "outside",
  min = 0,
  step = "any",
  changeValueOnScroll = false,
  ...props
}: MoneyInputProps) {
  const { startContentWrapper } = customSlots();

  return (
    <Input
      {...props}
      type="number"
      step={step}
      min={min}
      labelPlacement={labelPlacement}
      onWheel={changeValueOnScroll ? handleValueChangeOnScroll : undefined}
      startContent={
        <span
          className={startContentWrapper({
            className: props?.classNames?.startContentWrapper,
          })}
        >
          {startContent}
        </span>
      }
    />
  );
}
