import { UserPermissions } from "./permissions";
import { Shop } from "./shops";

export type CustomerResponse = {
  customer_info: CustomerInfo;
  selected_master: string;
  user_context: AccountType;
  zendesk_token?: string;
};

// this type is not complete yet
export type CustomerInfo = {
  owner_name: string;
  is_permitted: boolean;
  email_id: string;
  master_email_id: string;
  business_name: string;
  account_setup: AccountSetupState;
  account_type: AccountType;
  tier?: Tiers;
  permissions: UserPermissions[];
  enable_mechanical_vin_decode?: boolean;
  show_vin_decode?: boolean;
  enable_custom_user_roles?: boolean;
  has_accepted_terms?: boolean;
  referral_code: string;
  is_custom_branding?: boolean;
  logo_file_type?: string;
  logo?: {
    data: number[];
  };
  selected_shop_number?: number;
  shops?: Shop[];
  revv_plus_enabled?: boolean;
  stripe_charges_enabled?: boolean;
};

export enum Tiers {
  ORIGINAL = "original",
  FREE = "free_trial",
  ESSENTIALS = "essentials",
  PRO = "pro",
  ENTERPRISE = "enterprise",
  CUSTOM_ENTERPRISE = "enterprise_custom",
  CUSTOM_PRO = "pro_custom",
  CUSTOM_ESSENTIALS = "essentials_custom",
  CUSTOM = "custom",
  NO_PLAN = "no_plan",
}

export enum AccountSetupState {
  COMPLETE = "complete",
  INCOMPLETE = "incomplete",
}

export enum AccountType {
  SUPERADMIN = "superadmin",
  MASTER = "master",
  SERVICE = "service",
  TECHNICIAN = "technician",
}

export type User = {
  account_type: AccountType;
  email_id: string;
  owner_name: string;
  role_name: string;
  shop_names: { name: string; number: number }[];
  user_role_id: string;
};

export type CreateCustomerPayload = {
  service_email: string;
  master_email?: string;
  password: string;
  shop_numbers_list: number[];
  user_role_id: string;
};
