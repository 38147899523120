export function convertToTwoPlaceDecimalString(value: string | number): string {
  if (
    (typeof value === "string" && (!value || /^\s$/.test(value))) ||
    Number.isNaN(value)
  ) {
    return "";
  }

  let numberValue: number;

  const numberParts = String(value).split(".");

  // We truncate instead of rounding to keep consistency with other parts of the app
  if (numberParts[1] && numberParts[1]?.length > 2) {
    const truncatedNumber = Math.trunc(Number(value) * 100);
    numberValue = Number(truncatedNumber / 100);
  } else {
    numberValue = Number(value);
  }

  return Number.isNaN(numberValue) ? "" : numberValue.toFixed(2);
}

export function isNullOrUndefined(obj: string | number | null | undefined) {
  return obj === null || obj === undefined;
}

export function isNullOrEmpty(str: string | number | null | undefined) {
  return (
    isNullOrUndefined(str) || (typeof str === "string" && str.trim() === "")
  );
}

// Convert price to two decimal format and returns a float
export function convertToDollarsFloat(price: string) {
  if (isNullOrEmpty(price)) {
    return;
  }

  const numberParts = String(price).split(".");

  if (numberParts.length < 2) {
    return Number.parseFloat(price);
  }

  const decimalPart = numberParts[1];

  // If the user accidentally inputs > 2 decimal places, use bitwise operations to format it so we don't encounter rounding errors
  // ie. (539.999 -> 539.99, otherwise toFixed returns 540.00)
  // Can't use toFixed for both as it rounds certain floats incorrectly (539.42 -> 539.41)

  if (decimalPart && decimalPart.length > 2) {
    const multipliedNumber = Number(price) * 100;

    const truncatedNumber = Math.trunc(multipliedNumber);

    const result = truncatedNumber / 100;
    return result;
  }
  return Number.parseFloat(price);
}

// Turn into 500.00 notation
export function convertToDollarsString(price: string) {
  if (isNullOrEmpty(price)) {
    return;
  }
  const dollars = convertToDollarsFloat(price);

  return dollars?.toFixed(2);
}

export function discountPercentage(totalPrice: number, discount: number) {
  if (discount > 100) {
    return "100.00%";
  }

  if (discount < 0) {
    return "0.00%";
  }

  const priceIncludingDiscount = totalPrice + discount;
  const discountPercentage =
    !isNullOrUndefined(totalPrice) &&
    !isNullOrUndefined(discount) &&
    priceIncludingDiscount > 0
      ? (discount / priceIncludingDiscount) * 100
      : 0;

  return `${convertToDollarsString(discountPercentage?.toString())}%`;
}
