import { get } from "lodash-es";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

import {
  Dropdown,
  DropdownProps,
  MultiDropdown,
  MultiDropdownProps,
} from "../Dropdown/Dropdown";
import { FormErrorMessage } from "./FormErrorMessage";

export type FormDropdownProps = Omit<DropdownProps, "onChange"> & {
  name: string;
  rules?: UseControllerProps["rules"];
};

export function FormDropdown({ ...props }: FormDropdownProps) {
  return <FormBaseDropdown mode="single" {...props} />;
}

export type FormMultiDropdownProps = Omit<MultiDropdownProps, "onChange"> & {
  name: string;
  rules?: UseControllerProps["rules"];
};

export function FormMultiDropdown({ ...props }: FormDropdownProps) {
  return <FormBaseDropdown mode="multi" {...props} />;
}

type InnerDropdownProps = Omit<MultiDropdownProps, "onChange"> & {
  name: string;
  rules?: UseControllerProps["rules"];
  mode: "single" | "multi";
};

function FormBaseDropdown({
  name,
  rules,
  options,
  className,
  mode,
  ...props
}: InnerDropdownProps) {
  const { control } = useFormContext();
  const Cmp = mode === "single" ? Dropdown : MultiDropdown;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => {
        const isInvalid = !!get(formState.errors, name);
        const cName = `${isInvalid ? "border-red-500" : ""} ${className ?? ""}`;

        return (
          <>
            <Cmp
              {...props}
              className={cName}
              aria-label={name}
              aria-invalid={isInvalid}
              aria-errormessage={`${name}-error`}
              selectedValue={field.value || ""}
              selectedValues={field.value || []}
              options={options}
              onChange={field.onChange}
            />
            <FormErrorMessage name={name} />
          </>
        );
      }}
    />
  );
}
