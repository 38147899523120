import {
  Input as NextUIInput,
  InputProps as NextUiInputProps,
} from "@nextui-org/react";

export type InputProps = NextUiInputProps;

export function Input(props: InputProps) {
  const { classNames, variant, radius, ...restProps } = props;
  const clNames = classNames ?? {};

  return (
    <NextUIInput
      {...restProps}
      variant={variant ?? "faded"}
      radius={radius ?? "sm"}
      classNames={{
        ...clNames,
        // Fixing conflict between tailwindforms and nextui
        // See: https://github.com/nextui-org/nextui/issues/1572
        input: `border-0 focus:ring-0 pl-0 ${clNames.input ?? ""}`,
      }}
    />
  );
}
