import { useContext } from "react";

import { ConfirmProps } from "./Confirm";
import { ConfirmContext } from "./ConfirmProvider";

type ConfirmOptions = Omit<ConfirmProps, "onConfirm" | "onCancel">;

export function useConfirm() {
  const context = useContext(ConfirmContext);

  if (!context) {
    throw new Error("ConfirmContext is not defined");
  }

  return async function confirm(options: ConfirmOptions) {
    return new Promise<boolean>((resolve) => {
      context.showConfirm({
        ...options,
        onCancel: () => resolve(false),
        onConfirm: () => resolve(true),
      });
    });
  };
}
